import { API_URL } from "../config/environmentVariables";

/* global fetch  */

export class ApiService {
  private accessToken: string;

  constructor(accessToken: string) {
    this.accessToken = accessToken;
  }

  public getSalmonForwardingPrices = async () => {
    this.check();
    const res = await fetch(API_URL + "/salmonForwardingPrice", { headers: this.header, method: "GET" });
    return res.json();
  };

  private check = () => {
    if (!this.accessToken) throw new Error("Invalid access token");
  };

  private get header() {
    this.check();
    const header = new Headers();
    const bearer = `Bearer ${this.accessToken}`;
    header.append("Authorization", bearer);
    return header;
  }
}
