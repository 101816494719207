import * as React from "react";
import { useState, useEffect } from "react";
import {
  Menu,
  Button,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  SplitButton,
  MenuButtonProps,
  Spinner,
} from "@fluentui/react-components";
import { ApiService } from "../service/api";
import { ExcelService } from "../service/excel";
import { AuthenticationResult } from "@azure/msal-browser";
import { Container } from "./Container";
import { ErrorMessage } from "./ErrorMessage";

/* global console, Excel  */

interface IExcelControl {
  user: AuthenticationResult;
  checkActiveSession: Function;
}

const ExcelControls: React.FC<IExcelControl> = (props) => {
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    generateExcel();
  }, []);

  const generateExcel = async () => {
    try {
      setLoading(true);
      await props.checkActiveSession();
      const apiService = new ApiService(props.user.idToken);
      const salmonForwardingPrices = await apiService.getSalmonForwardingPrices();
      const excelService = new ExcelService();
      await excelService.init();

      excelService.deleteChart("SalmonForwardingPrices");
      const forwardingPriceTable = await excelService.createTable(
        "SalmonForwardingPrices",
        "A1:E1",
        [salmonForwardingPrices.columns],
        salmonForwardingPrices.rows
      );
      const chartDataRange = forwardingPriceTable.getRange();
      await excelService.createChart("SalmonForwardingPrices", chartDataRange, Excel.ChartType.line, {
        title: "Salmon Forwarding Prices",
        valueTitle: "Price (NOK)",
      });

      await excelService.sync();
    } catch (error) {
      console.log("error", error);
      setError("Something went wront! Please try again!");
    }
    setLoading(false);
  };

  const clearExcel = async (type?: "table" | "chart") => {
    try {
      const excelService = new ExcelService();
      await excelService.init();
      if (type) {
        if (type === "table") await excelService.clearTable("SalmonForwardingPrices");
        if (type === "chart") excelService.deleteChart("SalmonForwardingPrices");
      } else {
        await excelService.clearTable("SalmonForwardingPrices");
        excelService.deleteChart("SalmonForwardingPrices");
      }

      await excelService.sync();
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <Container>
      <div style={{ marginTop: 30 }}>
        <Button
          size="large"
          onClick={generateExcel}
          icon={loading ? <Spinner size="tiny" /> : undefined}
          disabled={loading}
        >
          Fetch Prices
        </Button>
      </div>
      <div style={{ marginTop: 30 }}>
        <Menu positioning="below-end">
          <MenuTrigger disableButtonEnhancement>
            {(triggerProps: MenuButtonProps) => (
              <SplitButton
                disabled={loading}
                size="large"
                menuButton={{ ...triggerProps }}
                primaryActionButton={{ onClick: () => clearExcel() }}
              >
                Clear
              </SplitButton>
            )}
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem onClick={() => clearExcel("table")}>Clear Table</MenuItem>
              <MenuItem onClick={() => clearExcel("chart")}>Clear Chart</MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
      <div style={{ marginTop: 10 }}>
        <ErrorMessage>{error}</ErrorMessage>
      </div>
    </Container>
  );
};

export default ExcelControls;
