import React, { useState, useEffect } from "react";
import { Button, makeStyles, Spinner } from "@fluentui/react-components";
import { officeSignIn } from "../service/auth";
import { Container } from "./Container";
import { ErrorMessage } from "./ErrorMessage";

/* global console, localStorage  */

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loginButton: {
    maxWidth: "180px",
    width: "180px",
  },
});

interface ILogin {
  setUser: Function;
  checkActiveSession: Function;
}

const Login: React.FC<ILogin> = (props) => {
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    checkActiveSession();
  }, []);

  const checkActiveSession = async () => {
    try {
      setLoading(true);
      await props.checkActiveSession();
    } catch (error) {
      console.log(error);
      setError("Login Failed! Please try logging in manually.");
    }
    setLoading(false);
  };

  const login = async () => {
    await officeSignIn(
      async (res) => {
        localStorage.setItem("username", res.result.account.username);
        props.setUser(res.result);
      },
      () => setError("Login failed, please try again!")
    );
  };

  return (
    <Container>
      <Button
        size="large"
        className={classes.loginButton}
        onClick={login}
        icon={loading ? <Spinner size="tiny" /> : undefined}
        disabled={loading}
      >
        {loading ? "Logging in..." : "Login"}
      </Button>

      <ErrorMessage>{error}</ErrorMessage>
    </Container>
  );
};

export default Login;
