import { makeStyles, tokens, shorthands } from "@fluentui/react-components";
import * as React from "react";

/* global require  */

const useStyles = makeStyles({
  headerContainer: {
    backgroundColor: tokens.colorBrandBackground,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.padding("20px"),
  },
});

export const Header: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.headerContainer}>
      <img
        width="315"
        height="60"
        src={require("../../../assets/logo.svg")}
        alt={"Salmon Forward Pricing"}
        title={"Salmon Forward Pricing"}
      />
    </section>
  );
};
