/* global Office, location, localStorage  */

import { msalInstance } from "../config/auth";

const loginURL =
  location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "") + "/login.html";

let loginDialog: Office.Dialog;

export const officeSignIn = async (handleLogin: Function, handleError?: Function) => {
  const processLoginMessage = (args) => {
    if (args.error) throw handleError("Something went wrong");
    let messageFromDialog = JSON.parse(args.message);
    if (messageFromDialog.status === "success") {
      // We now have a valid access token.
      loginDialog.close();
      handleLogin(messageFromDialog);
    } else {
      // Something went wrong with authentication or the authorization of the web application.
      loginDialog.close();
      throw new Error(messageFromDialog.result);
    }
  };

  const processLoginDialogEvent = (args) => {
    processDialogEvent(args, handleError);
  };

  await Office.context.ui.displayDialogAsync(loginURL, { height: 40, width: 30 }, (result) => {
    if (result.status === Office.AsyncResultStatus.Failed) {
      throw new Error(`${result.error.code} ${result.error.message}`);
    } else {
      loginDialog = result.value;
      loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLoginMessage);
      loginDialog.addEventHandler(Office.EventType.DialogEventReceived, processLoginDialogEvent);
    }
  });
};

const processDialogEvent = (args: { error: number; type: string }, handleError: Function) => {
  if (args.error) handleError("Login Failed");
};

export const getLoggedInUser = async () => {
  const accounts = msalInstance.getAllAccounts();
  const activeUser = accounts[0];
  let username: string;
  if (activeUser?.username) username = activeUser.username;
  else username = localStorage.getItem("username");
  const user = await msalInstance.ssoSilent({
    scopes: ["openid profile email"],
    loginHint: username,
  });
  console.log("user", user);
  return user;
};
