import * as React from "react";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

interface IContainer {
  children: React.ReactNode | React.ReactNode[];
}

export const Container: React.FC<IContainer> = (props) => {
  const classes = useStyles();
  return <div className={classes.container}>{props.children}</div>;
};
