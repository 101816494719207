import { PublicClientApplication } from "@azure/msal-browser";
import { AZURE_AD_CLIENT_ID, AZURE_AD_REDIRECT_URL } from "./environmentVariables";

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: AZURE_AD_CLIENT_ID,
    // clientId: "2eba321b-fca9-42b6-9f11-bcf8f8eb11ea",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: AZURE_AD_REDIRECT_URL, // Must be registered as "spa" type
  },
  cache: {
    cacheLocation: "localStorage", // needed to avoid "login required" error
    storeAuthStateInCookie: true, // recommended to avoid certain IE/Edge issues
  },
});
