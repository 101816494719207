import { makeStyles, tokens, Text } from "@fluentui/react-components";
import React from "react";

const useStyles = makeStyles({
  errorMessage: {
    color: tokens.colorPaletteRedBorderActive,
  },
});

interface IErrorMessage {
  children: React.ReactNode | React.ReactNode[];
}

export const ErrorMessage: React.FC<IErrorMessage> = (props) => {
  const classes = useStyles();
  if (!props.children) return <></>;
  return (
    <div style={{ marginTop: 5 }}>
      <Text className={classes.errorMessage}>{props.children}</Text>
    </div>
  );
};
