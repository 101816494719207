/* eslint-disable office-addins/call-sync-before-read */
/* global Excel  */

/**
 * @description Provides API to excel.
 * @todo finsih documentation.
 * @example
 * const excelService = new ExcelService();
 * await excelServvice.init();
 * */
export class ExcelService {
  public context: Excel.RequestContext;

  /**
   * @description Initalize excel context
   * @todo Find a better way to do this.
   * */
  init = async () => {
    await Excel.run(async (context) => {
      this.context = context;
    });
  };

  /**
   * @description Gets the active sheet or sheet with name. If sheet does not exist it will create new sheet with that name.
   * @param name string (optional)
   * */
  private getSheet = async (name?: string) => {
    if (name) {
      let sheet = this.context.workbook.worksheets.getItemOrNullObject(name);
      await this.sync();
      if (sheet.isNullObject) {
        sheet = this.context.workbook.worksheets.add(name);
        sheet.activate();
        await this.sync();
      }
      return sheet;
    }
    return this.context.workbook.worksheets.getActiveWorksheet();
  };

  /**
   * @description Creats new table with params. If table exists then returns existing table
   * @param name: string
   * @param range: string
   * @param columns: string[][]
   * @param name: (string | number)[][]
   * @example
   * const excelService = new ExcelService();
   * await excelServvice.init();
   * emailService.createTable("tableName", "A1:D3", [["Id", "Name", "Description"]], [[1, "abc", "description1"],[2, "xyz", "description2"]])
   * */
  createTable = async (name: string, range: string, columns: string[][], rows: (string | number)[][]) => {
    this.checkContext();
    const sheet = await this.getSheet();
    const existing = await this.getTableBodyRange(sheet, name);
    if (existing) {
      existing.range.values = rows;
      return existing.table;
    }

    const existingFormulas = await this.getAllForumlas();
    const table = sheet.tables.add(range, true);
    table.name = name;
    table.getHeaderRowRange().format.autofitColumns();
    table.getHeaderRowRange().values = columns;
    table.rows.add(0, rows);
    await this.sync();

    existingFormulas.map((formula) => {
      const cell = sheet.getRange(formula.address).getCell(0, 0);
      cell.values = [[formula.formula]];
    });
    await this.sync();

    return table;
  };

  private getTableBodyRange = async (sheet: Excel.Worksheet, name: string) => {
    try {
      this.checkContext();
      let table = sheet.tables.getItem(name);
      var range = table.getDataBodyRange();
      range.load("values");
      this.context.trackedObjects.add(range);
      this.context.trackedObjects.add(table);

      await this.sync();
      return { table, range };
    } catch (error) {
      return null;
    }
  };

  clearContent = async (range: string) => {
    this.checkContext();
    const sheet = await this.getSheet();
    const contentRange = sheet.getRange(range);
    contentRange.clear();
  };

  clearTable = async (name: string) => {
    try {
      this.checkContext();
      const sheet = await this.getSheet();
      const tableRange = sheet.tables.getItem(name).getRange();
      tableRange.clear();
    } catch (error) {
      return undefined;
    }
  };

  deleteTable = (name: string) => {
    this.checkContext();
    this.context.workbook.worksheets.getActiveWorksheet().tables.getItem(name)?.delete();
  };

  deleteChart = (name: string) => {
    this.checkContext();
    this.context.workbook.worksheets.getActiveWorksheet().charts.getItem(name)?.delete();
  };

  createChart = async (
    name: string,
    range: Excel.Range,
    type: Excel.ChartType,
    options?: Partial<{ title: string; valueTitle: string }>
  ) => {
    this.checkContext();
    const sheet = await this.getSheet();

    const chart = sheet.charts.add(type, range, Excel.ChartSeriesBy.auto);
    chart.name = name;
    chart.width = 600;
    chart.height = 400;

    chart.title.text = options.title;
    chart.legend.position = Excel.ChartLegendPosition.right;
    chart.legend.format.fill.setSolidColor("white");
    chart.axes.valueAxis.title.text = options.valueTitle;
    return chart;
  };

  getAllSheets = async () => {
    this.checkContext();
    const worksheets = await this.context.workbook.worksheets;
    worksheets.load();
    await this.context.sync();
    return worksheets.items.map((sheet) => sheet.name);
  };

  getAllForumlas = async (): Promise<{ formula: string; address: string }[]> => {
    this.checkContext();
    const sheet = await this.getSheet();
    const usedRange = sheet.getUsedRange().load(["formulas", "rowIndex", "columnIndex"]);

    await this.sync();
    const rows = usedRange.formulas;
    let cellFormulas = [];
    for (let rowIdx in rows) {
      const row = rows[parseInt(rowIdx)];
      for (let cellIdx in row) {
        const cell = row[parseInt(cellIdx)];
        if (typeof cell === "string" && cell.startsWith("=")) {
          const cellRange = sheet
            .getCell(usedRange.rowIndex + parseInt(rowIdx), usedRange.columnIndex + parseInt(cellIdx))
            .load("address");
          await this.context.sync();
          cellFormulas = [...cellFormulas, { formula: cell, address: cellRange.address }];
        }
      }
    }
    // await Promise.all(
    //   rows.map(async (row, rowIdx) => {
    //     await Promise.all(
    //       row.map(async (cell, cellIdx) => {
    //         if (typeof cell === "string" && cell.startsWith("=")) {
    //           const cellRange = sheet
    //             .getCell(usedRange.rowIndex + rowIdx, usedRange.columnIndex + cellIdx)
    //             .load("address");
    //           await this.context.sync();
    //           console.log("add formula", { formula: cell, address: cellRange.address });
    //           cellFormulas = [...cellFormulas, { formula: cell, address: cellRange.address }];
    //         }
    //       })
    //     );
    //   })
    // );
    return cellFormulas;
  };

  sync = async () => {
    this.checkContext();
    await this.context.sync();
  };

  private checkContext = () => {
    if (!this.context) throw new Error("Excel Service not initialized");
  };
}
