import * as React from "react";
import { AuthenticationResult } from "@azure/msal-browser";
import { makeStyles, typographyStyles, Text } from "@fluentui/react-components";

/* global localStorage  */

interface IInfo {
  user?: AuthenticationResult;
}

const useStyles = makeStyles({
  title: typographyStyles.subtitle1,

  paragraph: {
    ...typographyStyles.body2,
    letterSpacing: "0.0675em",
    fontStyle: "italic",
  },
});

export const Info: React.FC<IInfo> = (props) => {
  const styles = useStyles();
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Text as="h1" block className={styles.title}>
        Welcome {props.user?.account?.name ?? localStorage.getItem("username") ?? ""}!
      </Text>
      {props.user ? (
        <ul>
          <Text as="p" block className={styles.paragraph}>
            Salmon prices are fetched automatically when you open the addin.
          </Text>
          <Text as="p" block className={styles.paragraph}>
            If you want to fetch prices manually, click on &quot;Fetch Prices&quot; button.
          </Text>
        </ul>
      ) : (
        <ul>
          <Text as="p" block className={styles.paragraph}>
            We will help you to get salmon forwarding prices once you login as a authorized user
          </Text>
        </ul>
      )}
    </div>
  );
};
