import React, { useState } from "react";
import { getLoggedInUser } from "../service/auth";
import { Container } from "./Container";
import ExcelControls from "./ExcelControls";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Info } from "./Info";
import Login from "./Login";
import Progress from "./Progress";

export interface IAppProps {
  isOfficeInitialized: boolean;
}

const App: React.FC<IAppProps> = (props) => {
  const [user, setUser] = useState(undefined);

  const checkActiveSession = async () => {
    try {
      const loggedInUser = await getLoggedInUser();

      setUser(loggedInUser);
    } catch (error) {
      setUser(undefined);
      throw error;
    }
  };

  if (!props.isOfficeInitialized) {
    return <Progress message="Hold up a minute!" />;
  }

  return (
    <>
      <Header />
      <Info user={user} />
      {user ? (
        <ExcelControls user={user} checkActiveSession={checkActiveSession} />
      ) : (
        <Login setUser={setUser} checkActiveSession={checkActiveSession} />
      )}
      <Footer />
    </>
  );
};

export default App;
