import { makeStyles, shorthands, typographyStyles } from "@fluentui/react-components";
import * as React from "react";

/* global require  */

const useStyles = makeStyles({
  FooterContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    ...shorthands.padding("10px"),
  },
  text: typographyStyles.caption1,
});

export const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.FooterContainer}>
      <span className={classes.text}>Made with ❤️ by</span>
      <img
        width="30"
        height="25"
        src={require("../../../assets/logo_code.png")}
        alt={"Salmon Forward Pricing"}
        title={"Salmon Forward Pricing"}
      />
    </section>
  );
};
